@keyframes moveInDown {
	0% {
		opacity: 0;
		transform: translateY(-5rem);
	}
	/* 80% {
        transform: translateX(10px);
    } */
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInUp {
	0% {
		opacity: 0;
		transform: translateY(5rem);
	}
	/* 80% {
        transform: translateX(-10px);
    } */
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInRight {
	0% {
		opacity: 0;
		transform: translateX(-6rem);
	}
	/* 80% {
        transform: translateX(-10px);
    } */
	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes check {
	50% {
		transform: scale(1.2);
	}
}
