@import '../../sass/abstracts/variables.scss';

#about {
	scroll-margin-top: 145px;
}

.about {
	margin: 0 auto;
	background-image: url(../../img/glenn/touching-stars-expanded2-smallish.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;

	width: 100%;
	font-family: 'Playfair Display', serif;
	height: 100vh;
	background-color: #fff;
	padding: 2rem 0;
	position: relative;

	&-text {
		width: 50%;
		transition: all 1s ease;
		z-index: 200;
		position: absolute;
		top: 54%;
		left: 42%;
		transform: translate(-50%, -50%);

		&-description {
			font-size: 1.2rem;
			font-weight: 300;
			padding: 0.5rem 0;
			margin-bottom: 0.5rem;

			&:last-child {
				padding-bottom: 2rem;
			}
		}

		&-link {
			margin: 2rem auto 1rem;
			position: absolute;
			bottom: 3.5rem;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&-credentials {
		list-style: none;
		font-size: 1.4rem;
		font-style: italic;
		margin-top: 1rem;
	}

	&-image {
		width: 100%;
		margin: 0 auto;
		z-index: 1;

		&-img {
			display: block;
			width: 100%;
			position: absolute;

			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}
	}
}

@media screen and (min-width: 1963px) {
	.about {
		background-image: url(../../img/glenn/touching-stars-expanded2-small.jpg);
		background-position: 50% 0%;
	}
}

@media screen and (max-width: 1380px) {
	.about {
		&-text {
			left: 35%;
		}
	}
}

@media screen and (max-width: 1250px) {
	.about {
		height: 110vh;
		&-text {
			left: 35%;

			&-description {
				font-size: 1.4rem;
			}
		}
	}
}

@media screen and (max-width: 1140px) {
	.about {
		background: url(../../img/glenn/touching-stars-expanded2-small-tall.jpg) top
			center/cover no-repeat;
		text-align: center;
		height: 170vh;

		&-text {
			width: 88%;
			left: 50%;
			bottom: 3rem;
			transform: translateX(-50%);

			&-description {
				font-size: 1.6rem;
			}

			&-link {
				margin: 0.1rem 0;
			}
		}
	}
}

@media screen and (max-width: 960px) {
	.about {
		padding: 3rem 0;
		height: 180vh;

		&-text {
			width: 90vw;
			padding: 1rem;
			top: 49%;
			color: #000;

			&-description {
				font-size: 1.8rem;
				font-weight: 300;
				padding: 0.5rem 0;

				&:last-child {
					padding-bottom: 2rem;
				}
			}
		}
	}
}

@media screen and (max-width: 740px) {
	.about {
		background: url(../../img/glenn/touching-stars-small-tall3.jpg) top/cover
			no-repeat;
		height: 140vh;

		&-text {
			top: 36%;

			&-description {
				font-size: 1.6rem;
			}
		}
	}
}

@media screen and (max-width: 650px) {
	.about {
		&-text {
			&-description {
				font-size: 1.4rem;
			}

			&-link {
				width: 90%;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.about {
		height: 115vh;
		&-credentials {
			font-size: 1rem;
		}

		&-text {
			padding: 1rem;
			margin-bottom: 1rem;
			position: absolute;
			top: 26%;
			left: 50%;
			transform: translateX(-50%);

			&-description {
				font-size: 1.1rem;
			}
		}
	}
}
