.btn {
	color: #fff;
	font-size: 1.2rem;
	font-weight: 400;
	padding: 1rem 2rem;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	background: linear-gradient(to right, #2191f3, #1453b9);
	border-radius: 4px;
	display: inline-block;
	border: none;

	transition: all 0.2s ease;

	&:hover {
		transform: translateY(-4px);
		box-shadow: 0 3px 8px rgba(#000, 0.6);
	}

	&:active,
	&:focus {
		outline: none;
		transform: translateY(-2px);
		box-shadow: 0 1px 2px rgba(#000, 0.8);
	}

	&-primary {
		padding: 0.8rem 2.5rem;
		transition: all 0.3s;
		background: linear-gradient(to right top, #2191f3, #1453b9);

		&:hover {
			background: linear-gradient(to right bottom, #2191f3, #1453b9);
		}
	}

	&-secondary {
		width: 100%;
		outline: none;
		border: 1px solid #ddd;

		&:hover {
			transform: translateY(-2px);
		}

		&:active {
			transform: translateY(-1px);
		}
	}

	&-tertiary {
		transition: 0s;
		font-size: 1rem;
		display: inline;

		&:hover {
			background: #2191f3;
			box-shadow: none;
			transform: none;
		}

		&:active,
		&:focus {
			transform: none;
			box-shadow: none;
		}
	}

	&-quaternary {
		padding: 0.8rem 1.3rem;
		font-size: 1.1rem;
		border-radius: 8px;
		text-transform: none;
	}

	&-outline {
		padding: 0.6rem 1.3rem;
		border-radius: 2px;
		font-size: 1.3rem;
		display: inline;
		text-transform: none;
		background: transparent;
		color: #fff;
		border: 1px solid #fff;
		transition: all 0.3s ease-out;

		&:hover {
			background-color: #fff;
			color: #242424;
			transition: 250ms;
			box-shadow: none;
			transform: none;
		}
		&:active,
		&:focus {
			transform: none;
			box-shadow: none;
		}
	}

	&-right {
		text-align: end;
		margin-top: 2rem;

		@media screen and (max-width: 650px) {
			text-align: center;
		}

		& > *:not(:last-child) {
			margin-right: 3rem !important;
		}
	}

	&-center {
		display: flex;
		margin-top: 2rem;
		justify-content: center;
		align-items: center;
		width: 100%;

		& > *:not(:last-child) {
			margin-right: 3rem !important;
		}
	}

	&-top-right {
		text-align: end;
	}

	&-success {
		background: $color-success;
	}

	&-danger {
		background: $color-danger;
	}

	&-link {
		text-decoration: none;
		cursor: pointer;
		color: #fff;
		font-weight: 400;
		transition: all 2s ease;
		background-color: inherit;
		border: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&-icon {
		font-size: 1.8rem;
		background-color: inherit;
		border: none;

		@media screen and (max-width: 900px) {
			font-size: 1.5rem;
		}

		&:not(:first-child) {
			margin-left: 1.5rem;

			@media screen and (max-width: 900px) {
				margin-left: 1rem;
			}
		}

		.far,
		.fas {
			cursor: pointer;
			transition: all 0.2s ease-out;

			&:hover {
				transform: translateY(-4px);
			}

			&:active,
			&:focus {
				outline: none;
				transform: translateY(-2px);
			}
		}

		.fa-trash-alt {
			color: $color-danger;
		}

		.fa-edit,
		.fa-search {
			color: $color-success;
		}

		.fa-dollar-sign {
			color: $color-quinary;
		}

		.fa-redo-alt {
			color: $color-senary;
			position: relative;
			font-size: 28px;

			@media screen and (max-width: 500px) {
				font-size: 24px;
			}

			&::after {
				position: absolute;
				content: '$';
				font-size: 18px;
				top: 4px;
				right: 10px;

				@media not all and (min-resolution: 0.001dpcm) {
					@supports (-webkit-appearance: none) {
						right: 8.5px;
					}
				}

				@media screen and (max-width: 500px) {
					font-size: 15px;
					top: 4px;
					right: 8.5px;
				}
			}
		}
	}

	&-google {
		border-radius: 2rem !important;
		box-shadow: 0 0.5rem 1.5rem rgba($color-gray-dark, 0.2) !important;
		backface-visibility: hidden;
		transition: all 0.2s ease-out;

		div {
			margin: 0.15rem 0.2rem 0 !important;
			padding: 10px 6px 10px 10px !important;
			border-radius: 2rem !important;
		}
		span {
			padding: 10px 12px 10px 0 !important;
		}

		&:hover {
			transform: translateY(-0.3rem) scale(1.05);
			box-shadow: 0 1rem 2rem rgba($color-gray-dark, 0.2) !important;
		}

		&:active,
		&:focus {
			transform: translateY(-0.1rem);
			box-shadow: 0 0rem 1rem rgba($color-gray-dark, 0.2) !important   ;
		}
	}

	&-facebook {
		position: relative;

		.kep-login-facebook {
			backface-visibility: hidden;
			margin-left: 2rem;
			font-size: 1.2rem;
			padding: 0.75rem 3.9rem 0.75rem 0.9rem;
			position: relative;
			border: 1px solid transparent;
			border-radius: 2rem !important;
			font-weight: lighter;
			font-family: Arial, Helvetica, sans-serif;
			text-transform: unset;
			box-shadow: 0 0.5rem 1.5rem rgba($color-gray-dark, 0.2) !important;
			transition: all 0.2s ease-out;

			&:hover {
				transform: translateY(-0.3rem) scale(1.05);
				box-shadow: 0 1rem 2rem rgba($color-gray-dark, 0.2);
			}

			&:active,
			&:focus {
				transform: translateY(-0.1rem);
				box-shadow: 0 0rem 1rem rgba($color-gray-dark, 0.2);
			}

			&::after {
				content: 'Login';
				display: inline-block;
				font-family: Roboto, sans-serif;
				font-weight: 400;
				font-size: 0.95rem;
				top: 0.9rem;
				right: 1rem;
				position: absolute;
			}
		}
	}
}
