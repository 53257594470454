@import '../../../sass/abstracts/variables.scss';

.popup {
	&-content {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		width: 80%;
		z-index: 400;
		background-color: rgba(lighten($color-tertiary, 40%), 0.88);
		border-radius: 3px;
		box-shadow: 0 2rem 4rem rgba($color-dark, 0.2);
		padding: 1rem 1rem 2.5rem;
		overflow: hidden;
		animation: grow 0.35s;

		.heading-primary-subheading {
			font-weight: 300;
			font-size: 2.2rem;
			margin: 0 0 2rem -1.5rem;
			align-self: flex-start;

			@media screen and (max-width: 650px) {
				font-size: 2rem;
				margin: 0 0 1rem -0.5rem;
			}

			@media screen and (max-width: 500px) {
				margin: 0 0 1rem;
			}
		}

		@media screen and (max-width: 500px) {
			width: 93%;
			padding: 0.7rem 0.8rem 1.8rem;
		}

		.form__group .form__label {
			color: $color-dark;
		}

		&.confirmation {
			z-index: 600;
			width: 60%;

			@media screen and (max-width: 900px) {
				width: 70%;
			}

			@media screen and (max-width: 650px) {
				width: 75%;
			}

			@media screen and (max-width: 500px) {
				width: 85%;
				padding: 0.7rem 0.8rem 1.5rem;
			}
		}

		.fas:not(.fa-plus),
		.far {
			font-size: 1.7rem;

			@media screen and (max-width: 650px) {
				font-size: 1.35rem;
			}
		}
	}

	&-schedule {
		margin-top: 1rem;

		.reservation-form {
			margin: 1rem auto 0;
			padding: 0.7rem 1rem 0;
			width: 90%;

			@media screen and (max-width: 500px) {
				padding: 0;
			}
		}
	}

	&-job {
		padding: 2.5rem 3rem 0;

		@media screen and (max-width: 650px) {
			padding: 2rem 1rem 0;
		}
		@media screen and (max-width: 500px) {
			padding: 2rem 0.5rem 0;
		}

		.form {
			width: 80%;

			@media screen and (max-width: 650px) {
				width: 90%;
			}
			@media screen and (max-width: 500px) {
				width: 100%;
			}
		}
	}

	&-email {
		padding: 2.5rem 3rem 0;

		@media screen and (max-width: 650px) {
			padding: 2rem 0.3rem 0;
		}

		&-item {
			font-size: 1.2rem;
			align-self: flex-start;
			margin-bottom: 1rem;
			font-weight: 300;
		}

		&-title {
			font-size: 1.4rem;
			font-weight: 400;
			color: $color-dark;
		}
	}

	&-hour {
		padding: 2.5rem 2rem 0;

		@media screen and (max-width: 500px) {
			padding: 2.5rem 2rem 1.5rem;
		}

		.form {
			width: 80%;

			@media screen and (max-width: 650px) {
				width: 80%;
			}
			@media screen and (max-width: 500px) {
				width: 90%;
			}

			.popup-btns {
				margin-top: 1rem;
			}
		}
	}

	&-payment {
		.payment {
			width: 50%;
			margin: 3.5rem auto -0.5rem;
			display: flex;
			justify-content: center;
			flex-direction: column;

			@media screen and (max-width: 650px) {
				width: 70%;
			}
			@media screen and (max-width: 500px) {
				width: 85%;
				margin: 2.5rem auto -0.5rem;
			}

			&-text {
				align-self: center;
				font-size: 1.5rem;
				margin: 1rem 0;
			}

			.btn {
				margin: 0;
			}
		}
	}

	&-refund {
		margin-top: 2rem;
		.form {
			width: 50%;

			@media screen and (max-width: 900px) {
				width: 65%;
			}
			@media screen and (max-width: 650px) {
				width: 80%;
			}
			@media screen and (max-width: 500px) {
				width: 100%;
			}
		}
	}

	&-text {
		padding: 3rem 3rem 0;
		line-height: 2.8rem;
		font-size: 1.8rem;
		margin-bottom: 1rem;
		color: $color-dark;
		text-align: center;
		font-weight: 300;

		@media screen and (max-width: 650px) {
			padding: 2rem 2rem 0;
		}

		@media screen and (max-width: 500px) {
			padding: 1.5rem 1rem 0;
		}

		p:first-child {
			font-size: 2.2rem;
			margin-bottom: 0.8rem;

			@media screen and (max-width: 900px) {
				font-size: 2rem;

				@media screen and (max-width: 500px) {
					margin-bottom: 1rem;
				}
			}
		}
	}

	&-btns {
		margin: 1.5rem auto 0;

		.btn {
			min-width: 7rem;
			padding: 1rem;

			@media screen and (max-width: 500px) {
				font-size: 1rem;
				min-width: 5.5rem;
				padding: 0.8rem;
			}

			&:first-child {
				margin-right: 2rem;
			}
		}
	}

	&-heading {
		display: flex;
		justify-content: space-between;
		padding: 0 0.5rem 1rem;
		border-bottom: 1px solid $color-quaternary;

		@media screen and (max-width: 500px) {
			padding: 0 0.3rem 0.5rem;
		}

		&-img {
			width: 3.5rem;
			height: intrinsic;

			@media screen and (max-width: 500px) {
				width: 2.5rem;
			}
		}

		&-btn {
			background: inherit;
			border: none;
			height: fit-content;
			align-self: center;
			margin-right: 0.3rem;

			.fa-times {
				cursor: pointer;
				color: $color-dark;
				font-size: 2.5rem !important;
				transition: all 0.2s ease;

				@media screen and (max-width: 500px) {
					font-size: 2rem;
				}

				&:hover {
					color: lighten($color-dark, 10%);
					transform: translateY(-3px);
				}

				&:active,
				&:focus {
					outline: none;
					transform: translateY(-2px);
				}
			}
		}
	}

	&.hide {
		visibility: hidden;
		opacity: 0;

		& > .popup-content {
			animation: shrink 0.7s;
			animation-fill-mode: forwards;
		}
	}
}
