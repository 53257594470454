.react-calendar {
	&,
	& *,
	& *:before,
	& *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	/* @include respond(phone) {
        margin: 0 auto;
    } */

	width: 350px;
	max-width: 100%;
	background-image: linear-gradient(
			rgba($color-tertiary, 0.85),
			rgba($color-tertiary, 0.85)
		),
		url(../../img/drone-xray.jpg);
	background-position: top;
	background-size: cover;
	box-shadow: 0 1rem 1rem -1rem $color-gray-dark;
	border-radius: 0.5rem;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
	overflow: hidden;

	&--doubleView {
		width: 700px;
	}

	&__viewContainer {
		display: flex;
		margin: -0.5em;

		& > * {
			width: 100%;
			margin: 0.5em;
		}
	}

	button {
		margin: 0;
		border: 0;
		outline: none;

		&:enabled:hover {
			cursor: pointer;
		}
	}

	&__navigation {
		height: 44px;
		margin-bottom: 1em;
		display: flex;

		button {
			min-width: 44px;
			background: none;
			color: $color-white;

			&:enabled:hover,
			&:enabled:focus {
				background-color: rgba($color-secondary, 0.65);
			}

			&[disabled] {
				background-color: rgba($color-gray-light, 0.73);
				color: darken($color-gray-light, 25%);
			}
		}
	}

	&__month-view {
		&__weekdays {
			text-align: center;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 0.75em;

			&__weekday {
				padding: 0.5em;
				color: $color-gray-light;
			}
		}

		&__weekNumbers {
			font-weight: bold;

			.react-calendar__tile {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.75em;
				padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
			}
		}

		&__days__day {
			&:not(:disabled) {
				color: $color-white;
			}
			/* &--weekend {
                color: darken($color-septenary, 10%);
            } */
			&--neighboringMonth {
				color: lighten($color-gray-dark, 28%) !important;

				&:enabled:hover,
				&:enabled:focus {
					color: lighten($color-gray-dark, 47%) !important;
				}
			}

			&.reserved {
				background-color: $color-success;
			}

			&.disabled {
				background-color: $color-danger;
			}

			&.time-disabled {
				background-color: $color-quinary;
			}
		}
	}

	&__year-view &__tile,
	&__decade-view &__tile,
	&__century-view &__tile {
		padding: 2em 0.5em;
	}

	&__tile {
		max-width: 100%;
		text-align: center;
		padding: 0.75em 0.5em;
		background: none;

		&:not(:disabled) {
			color: $color-white;
		}

		&:disabled {
			background-color: rgba($color-gray-light, 0.73);
		}

		&:enabled:hover,
		&:enabled:focus {
			background-color: rgba($color-secondary, 0.85);
		}

		&--now {
			background: rgba($color-quaternary, 0.85) !important;
			color: $color-white;

			&:enabled:hover,
			&:enabled:focus {
				background: lighten($color-quaternary, 10%) !important;
			}
		}

		&-hasActive {
			background: $color-secondary;

			&:enabled:hover,
			&:enabled:focus {
				background: lighten($color-secondary, 7%);
			}
		}

		&--active {
			background: $color-primary !important;
			color: $color-white !important;

			&:enabled:hover,
			&:enabled:focus {
				background: lighten($color-primary, 7%);
				color: lighten($color-primary, 20%);
			}
		}
	}

	&--selectRange &__tile--hover {
		background-color: $color-primary;
	}
}
