.tooltip {
	position: relative;
	display: inline-block;

	.tooltiptext {
		visibility: hidden;
		background-color: $color-secondary;
		color: $color-white;
		opacity: 0.8;
		//width: max-content;
		text-align: center;
		border-radius: 0.6rem;
		padding: 0.5rem 1rem;
		top: 2.5rem;
		left: 40%;
		transform: translateX(-50%);
		transition: all 0.1s ease-out;

		/* Position the tooltip */
		position: absolute;
		z-index: 1;
	}

	&:hover .tooltiptext {
		visibility: visible;
		transform: translateX(-50%);
	}
}
