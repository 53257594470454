@import '../../../sass/abstracts/variables.scss';

.changepassword {
	margin: 0 auto;
	width: 70vw;
	padding: 2rem 1rem 3rem;
	border-radius: 3px;
	background-color: lighten($color-gray-light, 15%);

	&.float {
		position: absolute;
		top: 43%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@media screen and (max-width: 650px) {
		margin: 3rem auto;
		width: 80vw;
	}

	@media screen and (max-width: 500px) {
		margin: 2rem auto;
		padding: 1rem 1rem 3rem;
		width: 90vw;
	}

	.form {
		width: 40%;

		@media screen and (max-width: 650px) {
			width: 60%;
		}

		@media screen and (max-width: 500px) {
			width: 80%;
		}

		.btn {
			margin-top: 1.5rem;
		}
	}
}
