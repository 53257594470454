video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	z-index: -1;
	filter: brightness(80%);
}

.hero-container {
	height: 88vh;
	width: 100%;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	object-fit: contain;
	position: relative;

	&-logo {
		animation: moveInDown 1s;
		margin-left: -0.3rem;

		&-img {
			width: 11rem;
		}
	}

	&-image {
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}

.hero-box {
	position: absolute;
	top: 38%;
	left: 25%;
	transform: translate(-50%, -50%);
	width: 40%;
}
.hero-heading {
	color: #fff;
	font-size: 14rem;
	animation: moveInDown 1s;
}

.hero-subheading {
	margin: 1rem 0 2rem;
	color: #fff;
	text-shadow: 0.5px 0.5px #000;
	font-size: 2.2rem;
	letter-spacing: 1.3px;
	font-weight: 300;
	animation: moveInRight 1s;
}

.hero-button {
	animation: moveInUp 1s;
}

.hero-bottom {
	width: 100%;
	padding: 1.5rem;
	background: linear-gradient(to right, rgb(0, 102, 255), purple);
	color: #fff;
	text-align: center;
	position: absolute;
	font-size: 1.2rem;
	font-weight: 300;
	margin-top: -1rem;
}

.hero-buttonblue {
	color: #fff;
	font-size: 1.2rem;
	font-weight: 400;
	text-transform: uppercase;
	text-decoration: none;
	padding: 0.8rem 2.5rem;
	background: linear-gradient(to right top, #2191f3, #1453b9);
	border-radius: 4px;
	transition: all 0.3s;
	display: inline-block;

	&:hover {
		transform: translateY(-4px);
		box-shadow: 0 3px 8px rgba(#000, 0.6);
		background: linear-gradient(to right bottom, #2191f3, #1453b9);
	}

	&:active,
	&:focus {
		outline: none;
		transform: translateY(-2px);

		box-shadow: 0 1px 2px rgba(#000, 0.8);
	}
}

.fa-play-circle {
	margin-left: 4px;
}

@media screen and (max-width: 960px) {
	video {
		filter: brightness(70%);
	}
	.hero {
		&-container {
			height: 92vh;
		}

		&-box {
			width: 80%;
			top: 44%;
			left: 50%;
		}

		&-subheading {
			font-size: 2.6rem;
			letter-spacing: 1px;
		}
	}
}

@media screen and (max-width: 768px) {
	.hero-container {
		h1 {
			font-size: 50px;
			margin-top: -100px;
		}
		p {
			font-size: 30px;
		}
	}
}

@media screen and (max-width: 500px) {
	.hero {
		&-container {
			height: 80vh;
			text-align: center;
			margin: 0 auto;
			&-logo {
				&-img {
					width: 8rem;
				}
			}
		}
		&-box {
			margin-top: 0;
		}
		&-subheading {
			font-size: 2.2rem;
			margin: 0.5rem 0 1.5rem;
		}
	}
}
