@import '../../../sass/abstracts/variables.scss';

.footer {
	&-container {
		background-color: #231f20;
		padding: 0.8rem 0 0.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&-logo {
		width: 10rem;
		margin: 0 auto;
	}
}

/* Social Icons */
.social {
	&-icon {
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		&-link {
			color: #fff;
			font-size: 44px;
			cursor: pointer;
			width: 60px;
			transition: all 0.2s ease-out;

			&:hover {
				transform: translateY(-5px);
				transition: all 0.2s ease-out;
				color: lighten($color-quaternary, 25%);
			}
		}
	}

	&-media {
		// max-width: 1000px;
		width: 100%;
		text-align: center;
		margin: 0 auto;
		justify-content: center;
		align-items: center;

		&-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 1000px;
			margin: 0 auto;

			&-section {
				width: 33%;
				text-align: center;
			}
		}
	}

	&-logo {
		cursor: pointer;
	}
}

.website-rights {
	color: #fff;
	margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
	.footer {
		&-container {
			text-align: center;
			margin: 0 auto;
			padding: 0.5rem 0 1rem;
		}

		&-logo {
			width: 6.5rem;
		}
	}

	.social-media-wrap {
		flex-direction: column;
		margin: 0 auto;
		text-align: center;

		&-section {
			margin: 0.5rem 0 0.1rem;
			width: 100%;

			&:nth-child(1) {
				margin-top: 0.8rem;
				order: 3;
			}
			&:nth-child(2) {
				order: 1;
			}
			&:nth-child(3) {
				order: 2;
			}
		}
	}
}
