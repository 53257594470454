// COLORS
$color-primary: #2998ff;
$color-secondary: #0466c8;
$color-tertiary: #1a5a92d3;
$color-quaternary: #664586;
$color-quinary: #ffb900;
$color-senary: #ff8949;
$color-septenary: #3fc1c1d3;
$color-octonary: #5643fa;

$color-white: #fff;
$color-gray-dark: #242424;
$color-gray-light: rgb(177, 171, 171);
$color-danger: #c2010e;
$color-success: #05a6a6;
$color-dark: #002855;

//services
$all-items: (
	1: (
		#c5d4c9,
		#3f6e1a,
		'../../../img/small/services/elevation-map.jpg',
	),
	2: (
		#f5b056,
		#ff8949,
		'../../../img/small/services/vrm-cropped.jpg',
		55% 80%,
	),
	3: (
		#2998ff,
		#5643fa,
		'../../../img/small/services/construction.jpg',
	),
	4: (
		#3db428,
		#11b67f,
		'../../../img/small/services-construction.jpg',
	),
	5: (
		#52b788,
		#2d6a4f,
		'../../../img/small/services-roofing.jpg',
	),
	6: (
		#c88ff7,
		#664586,
		'../../../img/small/services/roofing.jpg',
	),
	7: (
		#0466c8,
		#002855,
		'../../../img/small/services/security.jpg',
		top/center,
	),
	8: (
		#3fc1c1,
		#1a5a92,
		'../../../img/small/services/consulting.jpg',
		center,
	),
);
