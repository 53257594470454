.form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;

	&__group {
		margin: 0.5rem auto;
		width: 100%;

		@media screen and (max-width: 650px) {
			margin: 0.2rem auto;
		}

		&-sub {
			width: 100%;
			display: flex;
			justify-content: space-between;

			&-item {
				display: inline-block;
				width: 48.5%;
				// padding: 0.3rem;

				&:last-child {
					padding-right: 0;
				}
			}
		}

		&.switch {
			display: flex !important;
			align-items: center;
			justify-content: center;
			margin-top: 2rem;
		}
	}

	&__input {
		font-size: 1.2rem;
		color: inherit;
		padding: 0.5rem 1rem;
		border-radius: 2px;
		background-color: rgba(255, 255, 255, 0.5);
		font-family: inherit;
		border: none;
		border-bottom: 3px solid transparent;
		width: 100%;
		display: inline-block;
		transition: all 0.3s;
		position: relative;

		-webkit-appearance: none;
		-moz-appearance: none;

		@media screen and (max-width: 650px) {
			padding: 0.4rem 0.8rem;
			font-size: 1rem;
		}

		&.space {
			margin: 0.6rem 0 2rem;
			color: black;
		}

		&::-webkit-input-placeholder,
		&.empty {
			color: rgb(88, 88, 88);
		}

		&:disabled {
			cursor: not-allowed;
			-webkit-text-fill-color: rgb(88, 88, 88);
			opacity: 1;
		}

		&:focus {
			outline: none;
			box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
			border-bottom: 3px solid $color-success;

			&:invalid {
				border-bottom: 3px solid $color-danger;
			}
		}

		&.textarea {
			&::-webkit-scrollbar {
				height: 0.8rem;
				width: 0.8rem;

				&-track {
					box-shadow: inset 0 0 4px $color-primary;
					border-radius: 10px;
				}

				&-thumb {
					box-shadow: inset 0 0 5px $color-dark;
					background: lighten($color-secondary, 5%);
					border-radius: 10px;
					cursor: pointer;

					&:hover {
						background: darken($color-secondary, 5%);
					}
				}
			}
		}

		&-switch {
			font-size: 1.5rem;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			width: 4.5rem;
			height: 2.25rem;
			background: #ddd;
			border-radius: 3em;
			position: relative;
			cursor: pointer;
			outline: none;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;

			&:checked {
				background: $color-primary;

				&::after {
					left: calc(100% - 1.5em);
				}
			}

			&::after {
				position: absolute;
				content: '';
				width: 1.5em;
				height: 1.5em;
				border-radius: 50%;
				background: #fff;
				-webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
				box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
				-webkit-transform: scale(0.7);
				transform: scale(0.7);
				left: 0;
				-webkit-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
			}
		}

		&-chk {
			display: none;

			&:checked + .form__label-chk span {
				&:first-child {
					border-color: $color-primary;
					background: $color-primary;
					animation: check 0.6s ease;

					svg {
						stroke-dashoffset: 0;
					}

					&::before {
						transform: scale(2.2);
						opacity: 0;
						transition: all 0.6s ease;
					}
				}

				&:last-child {
					color: $color-dark;
					transition: all 0.3s ease;

					&::after {
						transform: scaleX(1);
						transition: all 0.3s ease;
					}
				}
			}
		}
	}

	&__label {
		font-size: 1.1rem;
		margin-left: 0.9rem;
		font-weight: 700;
		margin-top: 0.4rem;
		display: inline-block;
		transition: all 0.25s;
		color: #fff;

		@media screen and (max-width: 650px) {
			font-size: 1rem;
		}

		&-switch {
			font-size: 1.4rem;
			color: $color-dark;
			margin-right: 2rem;

			font-size: 1.2rem;
		}

		&-chk {
			-webkit-user-select: none;
			user-select: none;
			-webkit-tap-highlight-color: transparent;
			cursor: pointer;

			span {
				display: inline-block;
				vertical-align: middle;
				transform: translate3d(0, 0, 0);

				&:first-child {
					position: relative;
					width: 24px;
					height: 24px;
					border-radius: 50%;
					transform: scale(1);
					vertical-align: middle;
					border: 1px solid $color-gray-light;
					transition: all 0.2s ease;

					svg {
						position: absolute;
						z-index: 1;
						top: 7px;
						left: 5px;
						fill: none;
						stroke: white;
						stroke-width: 2;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-dasharray: 16px;
						stroke-dashoffset: 16px;
						transition: all 0.3s ease;
						transition-delay: 0.1s;
						transform: translate3d(0, 0, 0);
					}

					&::before {
						content: '';
						width: 100%;
						height: 100%;
						background: #506eec;
						display: block;
						transform: scale(0);
						opacity: 1;
						border-radius: 50%;
						transition-delay: 0.2s;
					}
				}

				&:last-child {
					margin-left: 8px;
					font-size: 1.2rem;
					color: darken($color-gray-light, 20%);

					@media screen and (max-width: 650px) {
						font-size: 1rem;
					}
				}
			}

			&:hover span {
				&:first-child {
					border-color: $color-primary;
				}
				&:last-child {
					color: darken($color-primary, 15%);
				}
			}
		}
	}

	&__input:placeholder-shown + &__label,
	&__label.hide {
		opacity: 0;
		visibility: hidden;
		transform: translateY(-4rem);
	}

	&.filter {
		width: 55vw;
		position: relative;
		padding: 1rem;
		background-color: lighten($color-secondary, 48%);
		border-radius: 6px;
		box-shadow: 0 1rem 1rem -0.3 $color-gray-dark;
		margin: 1rem auto 4rem;

		@media screen and (max-width: 900px) {
			width: 65vw;
		}
		@media screen and (max-width: 650px) {
			width: 75vw;
			margin: 0 auto 2.5rem;
		}

		.filter {
			&-title {
				align-self: start;
				font-size: 1.8rem;
				color: $color-dark;
				margin-left: 1rem;

				@media screen and (max-width: 650px) {
					font-size: 1.4rem;
				}
			}
			&-icon {
				text-decoration: none;
				cursor: pointer;
				border: none;
				background-color: inherit;
				position: absolute;
				top: 1.2rem;
				right: 2rem;
				transition: all 0.2s ease-out;

				&:hover {
					transform: translateY(-2px);
				}

				& > * {
					color: $color-dark;
					font-size: 2rem;

					@media screen and (max-width: 650px) {
						font-size: 1.5rem;
					}
				}
			}

			&-content {
				padding: 1rem;
				margin-top: 0.8rem;
				width: 100%;
				transition: all 0.4s ease-out;
				display: block;

				@media screen and (max-width: 650px) {
					margin-top: 0;
				}
				@media screen and (max-width: 500px) {
					padding: 0.7rem;
				}

				&.hide {
					display: none;
				}
			}
		}
	}

	&-search {
		position: relative;

		&-display {
			width: 100%;
			transform: scale(0.99);
			font-weight: 300;
			color: $color-white;
			background-color: rgba(lighten($color-tertiary, 15%), 0.9);
			position: absolute;
			z-index: 200;
			top: 3rem;

			@media screen and (max-width: 650px) {
				top: 2.5rem;
			}

			overflow-y: auto;
			max-height: 30vh;

			&::-webkit-scrollbar {
				height: 0.5rem;
				width: 0.5rem;

				&-track {
					box-shadow: inset 0 0 4px $color-primary;
					border-radius: 10px;
				}

				&-thumb {
					box-shadow: inset 0 0 5px $color-dark;
					background: lighten($color-secondary, 5%);
					border-radius: 10px;
					cursor: pointer;

					&:hover {
						background: darken($color-secondary, 5%);
					}
				}
			}

			&.danger {
				background-color: $color-danger;
			}
		}

		&-item {
			padding: 1rem;
			margin: 0.5rem;

			&:not(.bg-danger) {
				cursor: pointer;

				&:hover {
					color: $color-dark;
					background-color: lighten($color-tertiary, 40%);
				}
			}
		}

		&-close {
			&,
			&:link,
			&:visited {
				background-color: inherit;
				text-decoration: none;
				cursor: pointer;
				border: none;
				position: absolute;
				top: 0.5rem;
				right: 0.8rem;
				transition: all 0.2s ease-out;
			}

			.far {
				color: $color-dark;
				font-size: 1.8rem;
				padding: 0 -0.1rem -0.1rem;
				border-radius: 50%;
				background-color: #dceeff;

				@media screen and (max-width: 650px) {
					font-size: 1.35rem;
				}
			}

			&:hover {
				transform: scale(1.1);
			}

			&:active,
			&:focus {
				outline: none;
			}
		}
	}

	@media screen and (min-width: 1326px) {
		&__group {
			&-sub {
				column-gap: 1rem;
			}
		}
	}

	@media screen and (max-width: 960px) {
		display: flex;
		flex-direction: column;

		&__group {
			width: 100%;
			display: inline-block;

			& select {
				width: 100%;
			}

			&-sub {
				display: inline-block;
				column-count: 1;

				&-item {
					width: 100%;
					&:not(:last-child) {
						margin-bottom: 0.5rem;
					}
				}
			}
		}
	}
}
