@import '../../sass/abstracts/variables.scss';

.schedule {
	display: flex;
	width: 100%;
	margin-top: 3rem;

	@media screen and (max-width: 650px) {
		flex-direction: column;
	}

	&-calendar {
		width: 40%;

		@media screen and (max-width: 650px) {
			width: 100%;
		}

		& > * {
			margin: 0 auto;
		}
	}

	&-details {
		width: 60%;
		padding: 1rem;

		@media screen and (max-width: 650px) {
			width: 100%;
			padding: 0.8rem;
			margin-top: 1rem;
		}
		@media screen and (max-width: 500px) {
			padding: 0.8rem 0;
		}

		.form {
			align-items: start;
		}

		&-main,
		&-title,
		&-subtitle,
		&-info {
			font-weight: 300;
		}

		&-main {
			font-size: 2rem;
			margin-bottom: 2rem;
			color: $color-dark;

			&.error {
				color: $color-danger;
			}
		}

		&-title {
			font-size: 1.8rem;
			color: $color-dark;
			margin: 0 0 1.5rem;
		}

		&-subtitle {
			font-size: 1.7rem;
			color: $color-dark;
		}

		&-info {
			font-size: 1.5rem;
			margin: 0.5rem 4rem;

			@media screen and (max-width: 500px) {
				margin: 0.5rem 0 0 2rem;
			}
		}

		.btn.btn-primary {
			margin-top: 1.5rem;
		}

		&-item {
			text-align: center;
			padding: 0.6rem;
			margin: 1rem auto;
			background-color: $color-tertiary;
			color: $color-white;
			border-radius: 0.5rem;
			cursor: pointer;
			backface-visibility: hidden;
			width: 70%;
			transition: all 0.2s ease-out;

			@media screen and (max-width: 650px) {
				width: 90%;
			}

			&:hover {
				background-color: lighten($color-dark, 15%);
				transform: scale(1.05);
			}
		}

		.text-warning {
			margin-top: 1rem;
		}
	}
}
