.contact {
  padding: 5rem 4rem 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  &-heading {
    font-size: 4rem;
    font-weight: 300;
    line-height: 4.5rem;
  }

  &-subheading {
    font-size: 2.4rem;
    font-weight: 200;
    padding-top: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .contact {
    padding: 3.5rem 3rem 2.5rem;

    &-heading {
      font-size: 3rem;
      line-height: 3.5rem;
    }

    &-subheading {
      font-size: 1.8rem;
    }
  }
}
