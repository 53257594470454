* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
}

html,
body {
	width: 100%;
	//height: 100%;
	margin: 0px;
	padding: 0px;
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
}

.container {
	padding: 3rem;

	@media screen and (max-width: 650px) {
		padding: 2rem 1.5rem;
	}
	@media screen and (max-width: 500px) {
		padding: 2rem 1rem;
		.heading-primary {
			font-size: 2rem;
			padding: 1.5rem 0;
		}
	}
}

.home,
.services,
.products,
.sign-up {
	height: 100%;
}

.services {
	// background-image: url("../src/img/small/img-2.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;
}

.products {
	// background-image: url("../src/img/small/img-9.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;

	@media screen and (max-width: 550px) {
		font-size: 2.9rem;
	}
}

#sign-up {
	scroll-margin-top: 40px;
}
.sign-up {
	// background-image: url("../src/img/small/img-7.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 100px;

	@media screen and (max-width: 550px) {
		font-size: 2.9rem;
	}
}
