@import '../../../sass/abstracts/variables.scss';

.user {
	margin: 0 auto;
	width: 80vw;
	padding: 4rem 2rem 0.1rem;
	background-image: linear-gradient(
			110deg,
			(rgba(74, 172, 202, 0.75) 65%, rgba(255, 255, 255, 0.35) 35%)
		),
		url(../../../img/small/services/consulting.jpg);
	background-position: center;

	@media screen and (max-width: 650px) {
		margin: 3rem auto;
		padding: 3rem 1rem;

		background-image: linear-gradient(
				110deg,
				(rgba(74, 172, 202, 0.75) 55%, rgba(255, 255, 255, 0.35) 4 5%)
			),
			url(../../../img/small/services/consulting.jpg);
	}

	@media screen and (max-width: 500px) {
		margin: 2rem auto;
		padding: 2rem 0;
	}

	.heading-primary {
		padding: 1.5rem 1rem;
		color: #fff;
		text-shadow: 0 3px 4px $color-tertiary;

		&::after {
			background-color: #fff;
			box-shadow: 0 3px 4px $color-tertiary;
		}
	}

	&-login {
		font-size: 1.1rem;
		margin-top: 1.5rem;
		text-align: center;
		color: lighten($color-primary, 35%);
		font-weight: 200;
	}

	.form {
		width: 70%;
		margin: 2rem auto;

		@media screen and (max-width: 650px) {
			width: 80%;
			margin: 1rem auto;
		}

		@media screen and (max-width: 500px) {
			width: 85%;
			margin: 0 auto;
		}
	}

	&-email {
		display: flex;
		justify-content: center;
		text-align: center;
		align-items: center;
		padding: 0 1rem;

		&-icon {
			color: lighten($color-primary, 30%);
			font-size: 5rem;
			margin-bottom: 2rem;
		}

		&-text {
			font-size: 2rem;
			font-weight: 400;
			color: $color-gray-dark;

			@media screen and (max-width: 650px) {
				font-size: 1.5rem;
			}

			&-email {
				font-size: 2.5rem;
				color: $color-dark;
				font-weight: 500;

				@media screen and (max-width: 650px) {
					font-size: 1.8rem;
				}

				@media screen and (max-width: 500px) {
					word-break: break-all;
				}
			}
		}

		&-subtext {
			font-size: 1.1rem;
			color: #fff /* lighten($color-primary, 35%) */;
			font-weight: 200;
			margin: 2rem 0;

			p {
				display: inline-block;
				margin-bottom: 0.5rem;
			}
		}

		.btn-link {
			font-size: 1.1rem;
		}
	}

	.far {
		font-size: 1.5rem;
	}

	.btn-center {
		@media screen and (max-width: 500px) {
			margin-top: 1rem;
		}
	}
}
