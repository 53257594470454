@import '../../../sass/abstracts/variables.scss';

#servicesfull {
	scroll-margin-top: 40px;
}

.servicesfull {
	padding: 6rem;
	display: flex;
	flex-direction: column;
	text-align: center;

	&-items {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
}

.popup {
	&-button {
		position: absolute;
		bottom: 2.5rem;
		left: 50%;
		transform: translateX(-50%);
	}
}

// CARD STYLING
.card-services {
	padding: 3rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
.card {
	//FUNCTIONALITY
	perspective: 90rem;
	-moz-perspective: 90rem;
	height: 25rem;
	width: 23%;
	margin: 0.5rem;
	background-color: transparent;

	&:hover &-inner {
		transform: rotateY(180deg);
	}

	/* &:hover &-heading-span {
		background-image: none;
	} */

	&-inner {
		position: relative;
		height: 100%;
		width: 100%;
		text-align: center;
		transition: all 1s ease-in-out;
		transform-style: preserve-3d;
		box-shadow: 0 1.5rem 4rem rgba(#000, 0.15);
	}

	&-front,
	&-back {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	&-front {
		background-blend-mode: multiply;
		background-size: cover;
		background-repeat: no-repeat;

		@each $name, $properties in $all-items {
			$light: nth($properties, 1);
			$dark: nth($properties, 2);
			$image: nth($properties, 3);
			$position: center;

			@if $name == 7 or $name == 2 {
				$position: nth($properties, 4);
			}

			&-#{$name} {
				background-image: linear-gradient(to right bottom, $light, $dark),
					url($image);
				@if $name == 7 or $name == 8 or $name == 2 {
					background-position: $position;
				}
			}
		}
	}

	&-back {
		transform: rotateY(180deg);

		@each $name, $properties in $all-items {
			$light: nth($properties, 1);
			$dark: nth($properties, 2);

			&-#{$name} {
				background-image: linear-gradient(to right bottom, $light, $dark);
			}
		}
	}

	//FRONT SIDE STYLING
	&-heading {
		font-size: 1.4rem;
		font-weight: 300;
		text-transform: uppercase;
		text-align: center;
		color: $color-white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 92%;

		&-span {
			padding: 0.1rem 0.9rem;
			-webkit-box-decoration-break: clone;
			box-decoration-break: clone;

			@each $name, $properties in $all-items {
				$light: nth($properties, 1);
				$dark: nth($properties, 2);

				&-#{$name} {
					background-image: linear-gradient(to right bottom, $light, $dark);
				}
			}
		}
	}

	&-text {
		padding: 1rem;

		ul {
			list-style: none;
			width: 80%;
			margin: 0 auto;

			li {
				text-align: center;
				font-size: 1.5rem;
				padding: 1rem;

				&:not(:last-child) {
					border-bottom: 1px solid #eee;
				}
			}
		}
	}

	//BACK SIDE STYLING
	&-cta {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 25rem;
		width: 100%;
	}

	&-price-box {
		text-align: center;
		color: $color-white;
		padding: 1rem;
		position: absolute;
		width: 100%;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&-price-only {
		font-size: 1.1rem;
		text-transform: uppercase;
		padding-bottom: 1rem;
	}

	&-price-value {
		font-weight: 200;
		font-size: 0.9rem;
	}
}

@media screen and (max-width: 1300px) {
	.card-services {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.card {
		display: flex;
		flex-direction: column;
		width: 90%;
		column-gap: 0.5rem;

		&-heading {
			font-size: 1.6rem;
		}
	}
}

@media screen and (max-width: 960px) {
	.card {
		display: flex;
		flex-direction: column;
		width: 90%;

		.card {
			&-heading {
				font-size: 1.2rem;
			}
		}
	}
}

@media screen and (max-width: 880px) {
	.card-services {
		padding: 1rem;
	}
	.card {
		&-price-value {
			font-size: 0.8rem;
		}
	}
}
@media screen and (max-width: 740px) {
	.servicesfull {
		padding: 2rem;
	}

	.popup {
		&-button {
			bottom: 1.8rem;
		}
	}

	.card-services {
		display: flex;
	}

	.card {
		display: flex;
		flex-direction: column;
		width: 90%;

		&-heading {
			font-size: 1.6rem;
		}

		&-price-value {
			font-size: 1.1rem;
		}
	}
}

@media screen and (max-width: 550px) {
	.servicesfull {
		padding: 0.3rem;
	}

	.card {
		&-price-value {
			font-size: 1rem;
		}
	}
}

@media screen and (max-width: 400px) {
	.popup {
		&-button {
			bottom: 1.8rem;
		}
	}

	.card {
		&-price-value {
			font-size: 0.8rem;
		}
	}
}
