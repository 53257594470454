@import '../../../sass/abstracts/variables.scss';

.reservation {
	.btn-link {
		color: $color-quaternary;
		font-size: 1.5rem;
	}

	.heading-primary-subheading {
		&.error {
			color: $color-danger;
			text-align: center;
		}
	}

	.form__input {
		background-color: #dceeff;
	}

	&-list {
		width: 70vw;
		margin: 0 auto;
		padding: 1rem 1.5rem;
		background-color: lighten($color-secondary, 48%);

		@media screen and (max-width: 900px) {
			width: 80vw;
		}

		@media screen and (max-width: 500px) {
			width: 85vw;
			padding: 0.5rem 1.2rem;
		}

		.heading-primary-subheading {
			margin: 1rem 0;
			text-align: center;
		}
	}

	&-item {
		display: flex;
		align-items: center;
		background-color: #fff;
		padding: 1.3rem;
		margin: 0.5rem 0;
		border-radius: 4px;
		font-weight: 300;
		text-align: center;

		@media screen and (max-width: 650px) {
			flex-direction: column;

			&-date,
			&-status {
				margin-bottom: 1rem;
			}
		}

		@media screen and (max-width: 500px) {
			margin: 0.8rem 0;
		}

		&-date {
			font-size: 1.3rem;
			width: 15%;

			@media screen and (max-width: 900px) {
				width: 20%;
			}

			@media screen and (max-width: 650px) {
				font-size: 1.2rem;
				width: 100%;
			}

			.date {
				display: block;
			}
		}

		&-status {
			font-size: 1.2rem;
			width: 70%;

			@media screen and (max-width: 900px) {
				width: 60%;
			}

			@media screen and (max-width: 650px) {
				font-size: 1.15rem;
				width: 100%;
			}
		}

		&-icons {
			width: 15%;
			display: flex;
			justify-content: space-around;

			@media screen and (max-width: 900px) {
				width: 20%;
			}

			@media screen and (max-width: 650px) {
				width: 100%;
			}
		}

		&-title {
			font-size: 1.4rem;
			color: $color-dark;

			@media screen and (max-width: 650px) {
				font-size: 1.3rem;
			}
		}
	}
}
