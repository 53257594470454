@import '../../../sass/abstracts/variables.scss';

.section-login {
	position: relative;
	overflow: hidden;
	margin: 6rem 0;

	@media screen and (max-width: 650px) {
		margin: 3rem 0;
	}
}

.login {
	min-height: inherit;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&-card {
		display: flex;
		width: 80vw;
		background-color: lighten($color-gray-light, 5%);
		border-radius: 0.5rem;
		position: relative;
		overflow: hidden;

		@media screen and (max-width: 500px) {
			width: 90vw;
			flex-direction: column;
		}

		.form {
			width: 50%;
			padding: 6rem 3rem 4rem;

			@media screen and (max-width: 900px) {
				width: 65%;
			}

			@media screen and (max-width: 650px) {
				width: 100%;
				padding: 5rem 3rem 3rem;
				z-index: 100;
				background-color: inherit;
			}

			@media screen and (max-width: 500px) {
				padding: 4rem 1rem 3rem;
			}

			&-section {
				margin: 5rem auto 1rem;
				width: 80%;

				@media screen and (max-width: 500px) {
					width: 90%;
					margin: 3rem auto 0;
				}
			}
		}

		&-sign {
			font-size: 1.1rem;
			margin-top: 1.5rem;
			color: $color-white;
			font-weight: 400;

			&:nth-child(3) {
				margin-top: 0.5rem;
			}
		}

		&-smedia {
			margin-top: 2rem;
		}
	}

	&-img {
		width: 50%;
		background-color: $color-quaternary;
		background-image: linear-gradient(
				rgba($color-white, 0.5),
				rgba($color-white, 0.6)
			),
			url(../../../img/logoDRONE-dark-cropped-final.png);
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;

		@media screen and (max-width: 650px) {
			display: none;
		}
	}

	.btn {
		margin-top: 0.5rem;

		@media screen and (max-width: 500px) {
			margin-top: 1rem;
			font-size: 1rem;
			padding: 0.7rem 2rem;
		}
	}

	.heading-primary {
		padding: 0;
		width: 100%;
		text-align: center;

		&::after {
			margin: 0 auto;
		}
	}

	.btn-link {
		font-size: 1rem;
		color: $color-dark;
	}

	.alert {
		margin: -1.5rem 0 1.5rem;
	}
}
