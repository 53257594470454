@import '../../../sass/abstracts/variables.scss';

.outreach {
	&-jobs {
		&-list {
			margin: 0 1rem 1.5rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@media screen and (max-width: 650px) {
				margin: 0 1rem;
			}
			@media screen and (max-width: 500px) {
				margin: 0;
			}
		}

		&-item {
			width: 49%;
			margin: 1rem 0;

			@media screen and (max-width: 650px) {
				width: 100%;
				margin: 0.6rem 0;
			}

			span {
				&:last-child {
					width: 85%;

					@media screen and (max-width: 500px) {
						width: 80%;
					}
				}
			}
		}
	}

	&-list-jobs:not(:last-child) {
		margin-bottom: 0.5rem;
	}

	.btn-link {
		color: $color-dark;
	}
}
