.list {
	/* margin: 3rem 4rem;

	@media screen and (max-width: 650px) {
		margin: 2rem 1.5rem;
	}
	@media screen and (max-width: 500px) {
		margin: 2rem 1rem;
		.heading-primary {
			font-size: 2rem;
			padding: 1.5rem 0;
		}
	} */

	.btn {
		@media screen and (max-width: 650px) {
			&:not(.btn-quaternary) {
				font-size: 0.9rem;
				padding: 0.7rem 1rem;
			}

			&-primary .fas {
				font-size: 1rem;
			}
		}

		&-tertiary {
			margin: 0 auto;
			display: table;
			padding: 1rem 1.5rem;

			@media screen and (max-width: 650px) {
				margin: 0.7rem auto 0;
				font-size: 0.9rem;
				padding: 0.8rem 1.3rem;
			}
		}

		&-quaternary {
			@media screen and (max-width: 650px) {
				font-size: 1rem;
			}
		}
	}

	&-total {
		font-size: 2.2rem;
		margin: 0.5rem 2rem 0;
		font-weight: 300;

		@media screen and (max-width: 650px) {
			font-size: 1.8rem;
			margin: 1.5rem 1.5rem 0;
		}

		&-title {
			text-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
		}
	}

	#status {
		width: 100% !important;
	}
}
