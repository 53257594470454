.wrapper {
	position: relative;
	overflow-x: auto;
	white-space: nowrap;
	padding: 0 0.9rem 2.2rem;
	max-width: 100%;

	@media screen and (max-width: 1200px) {
		padding: 0 0.5rem 2.2rem;
	}

	@media screen and (max-width: 900px) {
		padding: 0 0 2.35rem;
	}

	&::-webkit-scrollbar {
		height: 1rem;
		width: 1rem;

		&-track {
			box-shadow: inset 0 0 4px $color-primary;
			border-radius: 10px;
		}

		&-thumb {
			box-shadow: inset 0 0 5px $color-dark;
			background: lighten($color-secondary, 5%);
			border-radius: 10px;
			cursor: pointer;

			&:hover {
				background: darken($color-secondary, 5%);
			}
		}
	}

	&-popup {
		max-width: none;
		max-height: 70vh;
		white-space: unset;

		&::-webkit-scrollbar {
			height: 0.5rem;
			width: 0.5rem;
		}
	}
}
