@import '../../../sass/abstracts/variables.scss';

.manage-schedule {
	&-row {
		display: flex;
		width: 100%;
		margin-top: 2rem;

		@media screen and (max-width: 650px) {
			flex-direction: column;
			margin-top: 0;
		}

		.react-calendar {
			margin: 0 auto;
		}
	}

	&-calendar {
		width: 40%;

		@media screen and (max-width: 650px) {
			width: 100%;
		}
	}

	&-details {
		width: 60%;

		@media screen and (max-width: 650px) {
			width: 100%;
			margin-top: 1rem;
		}
		@media screen and (max-width: 500px) {
			margin-top: 2rem;
		}

		.fas,
		.far {
			font-size: 1.4rem;
		}

		.fa-search {
			color: $color-white;
		}

		.btn-quaternary .fas {
			font-size: 1.2rem;
		}

		&-title {
			font-weight: 400;
			color: $color-dark;
			font-size: 1.7rem;
			margin: 1rem 2rem 1rem;

			@media screen and (max-width: 650px) {
				font-size: 1.5rem;
			}
		}

		&-res {
			margin: 2rem 1rem 1rem;

			@media screen and (max-width: 500px) {
				margin: 2rem 0 0;
			}
		}

		&-item {
			padding: 0.8rem;
			color: $color-white;
			border-radius: 0.8rem;
			margin: 0.5rem 0;
			background-color: $color-tertiary;
			display: flex;
			align-items: center;
			justify-content: space-around;
			transition: all 0.2s ease-out;
			transform: scale(0.95);

			&.disabled {
				background-color: $color-quinary !important;

				&:hover {
					transform: scale(1);
					background-color: lighten($color-quinary, 5%);
				}
			}

			&:hover {
				transform: scale(1);
				background-color: lighten($color-tertiary, 5%);
			}
		}

		.btn {
			&-right {
				display: flex;
				justify-content: flex-end;
			}

			&-link {
				background-color: unset;
			}

			&-icon {
				font-size: 1.3rem;
				background-color: unset;
			}

			@media screen and (max-width: 500px) {
				padding: 0.8rem 1rem;
				font-size: 1rem;
				display: flex;
				align-items: center;
			}
		}
	}

	&-color {
		&-guide {
			margin: 2rem auto 0;
			width: max-content;
			font-size: 1.1rem;

			@media screen and (max-width: 650px) {
				display: none;
			}
		}
		&-item {
			margin-left: -1rem;
			padding: 0.3rem 0;

			.fas {
				margin-right: 0.5rem;
				font-size: 1.5rem;
			}

			.reserved {
				color: $color-success;
			}
			.disabled {
				color: $color-danger;
			}
			.partially-disabled {
				color: $color-quinary;
			}
		}
	}
}
