@import '../../../sass/abstracts/variables.scss';

.navbar {
	background: #242424;
	position: fixed;
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	z-index: 300;
	width: 100%;

	&.activate {
		padding: 0.4rem;
	}

	&-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		justify-content: center;
		max-width: 99%;
		z-index: 999;
	}

	.nav {
		&-menu {
			display: flex;
			list-style: none;
			justify-content: center;
			align-items: center;
			//margin-left: 4rem;
		}

		&-item {
			font-size: 0.95rem;
			text-transform: uppercase;

			@media screen and (max-width: 1000px) {
				&.hide {
					display: none;
				}
			}
		}

		&-links {
			color: #fff;
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			margin: 0.4rem;
			padding: 0.3rem 0.7rem;
			border-right: 1px solid #ffe;
			text-align: center;

			&::after {
				content: '';
				position: absolute;
				width: 90%;
				height: 3px;
				left: 50%;
				bottom: 0;
				background-color: transparent;
				transition: all 0.2s ease-out;
				transform: translate(-50%, -5px);
			}

			&:last-child {
				border-right: none;
			}

			&:hover::after {
				background-color: #fff;
				transform: translate(-50%, 0);
				transition: all 0.3s ease-out;
			}

			&-mobile,
			&-salute {
				display: none;
			}
		}

		&-button {
			position: absolute;
			right: 3rem;
			line-height: 3.7rem;
		}

		&-salute {
			margin-right: 13rem;
			font-size: 1.3rem;
			display: inline-block;
		}
	}
}

.menu-icon {
	display: none;
}

.fa-bars {
	color: #fff;
}

.fa-times {
	color: #fff;
	font-size: 2rem;
}

@media screen and (max-width: 960px) {
	.navbar {
		.nav {
			&-menu {
				flex-direction: column;
				width: 100%;
				height: 100vh;
				top: 0;
				left: -100%;
				opacity: 0;
				transition: all 0.5s ease;
				position: fixed;
				margin-left: 0;
			}

			&-menu.active {
				background: #242424;
				left: 0;
				opacity: 1;
				transition: all 0.5s ease;
			}

			&-item {
				height: 90px;
			}

			&-links {
				text-align: center;
				padding: 1rem;
				display: table;
				font-size: 1.8rem;

				&::after {
					height: 0;
				}

				&:hover {
					background-color: #fff;
					color: #242424;
					border-radius: 0;
				}

				&-mobile {
					display: inline-block;
					margin-top: 1.5rem;
					font-size: 1.6rem;
				}

				&-salute {
					display: inline-block;
					font-size: 1.8rem;
					height: 4rem;

					.btn-link {
						color: lighten($color-secondary, 30%);
					}
				}
			}

			&-salute {
				display: none;
			}
		}

		.menu-icon {
			display: block;
			position: relative;
			z-index: 200;
			font-size: 1.8rem;
			cursor: pointer;
		}
	}
}
