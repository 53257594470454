@import '../../sass/abstracts/variables.scss';

.reservation-form {
	width: 75%;
	margin: 2.5rem auto 0;
	padding: 0.7rem 1rem 2rem;

	@media screen and (max-width: 900px) {
		width: 95%;
	}
	@media screen and (max-width: 500px) {
		padding: 0.5rem 0.5rem 1rem;
	}

	.heading-primary-subheading {
		margin: 0 0 0.8rem -1.5rem !important;
		display: inline-block;

		@media screen and (max-width: 900px) {
			margin: 0 0 0.8rem !important;
		}
	}

	&.border {
		border-radius: 4px;
		border: 2px solid $color-dark;
	}

	&-inner {
		width: 80%;
		margin: 0 auto;

		@media screen and (max-width: 650px) {
			width: 90%;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
		}
	}

	&-item {
		font-size: 1.1rem;
		margin: 1rem 0.2rem;

		@media screen and (max-width: 650px) {
			font-size: 1rem;
		}
	}

	&-title {
		font-size: 1.3rem;
		margin-right: 0.5rem;
		font-weight: 500;
		color: $color-dark;

		@media screen and (max-width: 650px) {
			font-size: 1.1rem;
		}
	}

	&-total {
		font-size: 1.8rem;
		font-weight: 300;
		margin-top: 2rem;

		@media screen and (max-width: 500px) {
			margin-top: 1rem;
		}

		&-title {
			font-size: 2rem;
			font-weight: 400;
			color: $color-dark;
		}
	}

	.jobs-list {
		margin: 1.5rem 0 2rem;
		position: relative;

		@media screen and (max-width: 650px) {
			margin: 1rem 0;
		}

		&-item {
			margin: 1rem;
			border: 1px solid $color-dark;
			padding: 1rem 1.8rem;
			border-radius: 6px;
			display: flex;
			justify-content: space-between;

			@media screen and (max-width: 650px) {
				margin: 1rem 0;
				flex-direction: column;
				align-items: center;
			}

			&-group {
				width: 90%;

				&.full {
					width: 100%;
				}

				@media screen and (max-width: 650px) {
					width: 100%;
				}

				.switch {
					.form__input-switch {
						transform: scale(0.7);
					}

					.form__label-switch {
						font-size: 1.1rem;
						margin: 0 0.5rem 0 0;
					}
				}
			}

			&-title {
				font-size: 1.3rem;
				color: darken($color-quaternary, 10%);
				margin-left: -0.4rem;
				margin-bottom: 1rem;
			}

			&-price {
				display: flex;
				justify-content: flex-end;

				table {
					width: 40%;
					margin-top: -1rem;

					@media screen and (max-width: 1300px) {
						width: 50%;
					}
					@media screen and (max-width: 1100px) {
						width: 60%;
						margin-top: 0;
					}
					@media screen and (max-width: 500px) {
						width: 95%;
						margin-top: 1rem;
					}

					td {
						padding: 0.3rem 0.1rem;
						text-align: start;

						&:last-child {
							min-width: 50%;
						}
					}

					.text-dark {
						font-size: 1.3rem;
						font-weight: 400;
					}
				}
			}
		}

		&-title {
			font-size: 1.5rem;
			margin-bottom: 0.8rem;
			margin-left: -0.2rem;
			color: $color-dark;
		}

		.btn-right {
			margin-top: 0;
		}
	}

	&-cancel {
		background-color: inherit;
		border: none;
		cursor: pointer;
		transition: all 0.2s ease-out;
		backface-visibility: hidden;
		transform: perspective(1px) scale(0.9) translateY(4px);

		.fas {
			color: $color-dark;
			font-size: 2rem;
		}

		&:hover {
			backface-visibility: hidden;
			-webkit-transform: perspective(1px) scale(1) translateY(4px);
			transform: perspective(1px) scale(1);
		}

		&:active,
		&:focus {
			outline: none;
		}
	}

	.form__group {
		transition: all 0.2s ease-out;
		opacity: 1;
		height: auto;

		.form__label {
			color: $color-dark;
		}

		&.hide {
			display: table-column;
			transition: all 0.2s ease-out;
			height: 0;
			transform: translateY(-10px);
			opacity: 0;
		}

		&.show {
			margin: 1rem 0 -1rem;
		}
	}

	.switch {
		margin-top: 0;
		justify-content: flex-end;

		@media screen and (max-width: 500px) {
			margin-top: 0;
		}

		.form__input-switch {
			transform: scale(0.8);
		}

		.form__label-switch {
			font-size: 1.3rem;
			margin: 0 0.5rem 0 0;
		}
	}

	.text-warning {
		margin: 1rem 2rem 2rem;
	}
}
