@import '../abstracts/variables.scss';

// .heading-secondary {
//     display: inline-block;
//     margin: 0 auto;
//     font-size: 2.4rem;
//     padding-bottom: 1rem;
//     z-index: 99;
//     text-shadow: 0 2px 2px rgba(0,0,0,.9);
//     position: relative;
//     transition: all .6s ease;

//     &::after {
//         content: '';
//         display: block;
//         margin: 0 auto;
//         width: 70%;
//         height: 2px;
//         background-color: inherit;
//         position: absolute;
//         bottom: 0%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         transition: all .6s ease;
//     }

//     &:hover::after {
//         width: 80%;
//         // background-color: darken(currentColor, .10);
//     }
// }

// .color-gradient {
//     background-image: linear-gradient(to right, $color-primary, $color-octonary);
//     -webkit-background-clip: text;
//     color: transparent;
// }

// .color-white {
//     color: #fff;
// }

.heading {
	&-primary {
		font-size: 2.8rem;
		font-weight: 200;
		text-align: center;
		text-transform: uppercase;
		line-height: 3rem;
		letter-spacing: 0.5rem;
		padding: 2rem 0;
		z-index: 99;
		color: #242424;
		text-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);

		&::after {
			content: '';
			display: block;
			margin: 1rem auto;
			width: 60%;
			height: 1px;
			text-align: center;
			background-color: #242424;
			position: relative;
			left: 0;
			transition: all 0.6s ease;
		}

		&-subheading {
			font-size: 1.6rem;
			font-weight: 200;
			margin-bottom: 2rem;

			&-update {
				margin-left: 1.5rem;
				font-size: 1.3rem;
				color: $color-dark;
				display: inline-block;

				@media screen and (max-width: 650px) {
					margin-top: 1rem;
					display: block;
					text-align: center;
					font-size: 1.2rem;
				}
			}
		}
	}

	&-secondary-gradient {
		display: inline-block;
		margin-bottom: 1.8rem;
		font-size: 1.9rem;
		font-weight: 300;
		padding-bottom: 1rem;
		z-index: 99;
		background-image: linear-gradient(
			to right,
			$color-primary,
			$color-octonary
		);
		-webkit-background-clip: text;
		background-clip: text;
		color: transparent;
		position: relative;
		transition: all 0.6s ease;

		&:hover::after {
			width: 80%;
			background-color: $color-octonary;
		}

		&::after {
			content: '';
			display: block;
			margin: 0 auto;
			width: 70%;
			height: 3px;
			background-color: $color-primary;

			position: absolute;
			bottom: 0%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all 0.6s ease;
		}
	}

	&-secondary-white {
		font-size: 2.4rem;
		padding-bottom: 1rem;
		z-index: 99;
		color: #fff;
		text-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);

		&:hover::after {
			width: 80%;
		}
		&::after {
			content: '';
			display: block;
			margin: 0 auto;
			width: 70%;
			height: 3px;
			background-color: $color-white;

			position: absolute;
			bottom: 0%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all 0.6s ease;
		}
	}
}

.text {
	&-dark {
		color: $color-dark !important;
	}

	&-danger {
		color: $color-danger !important;
	}

	&-warning {
		font-size: 1.3rem;
		text-align: center;
		color: $color-danger;
		font-weight: 300;
	}
}
