@import '../../../sass/abstracts/variables.scss';

.activation {
	text-align: center;

	&.float {
		position: absolute;
		top: 43%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.heading-secondary-gradient {
		font-size: 3rem;
	}

	&-text {
		margin: 2rem 0;
		font-size: 2rem;
		font-weight: 300;
		color: $color-quaternary;
	}

	&-error {
		&-header {
			margin-top: -8rem;
			margin-bottom: 2rem;
			text-align: center;
			font-size: 4rem;
			color: $color-gray-dark;
			font-weight: 400;

			@media screen and (max-width: 650px) {
				font-size: 3rem;
			}
			@media screen and (max-width: 500px) {
				font-size: 2.5rem;
			}
		}

		&-text {
			font-size: 2rem;
			color: $color-danger;
			font-weight: 300;

			@media screen and (max-width: 650px) {
				font-size: 1.8rem;
			}
			@media screen and (max-width: 650px) {
				font-size: 1.5rem;
			}
		}
	}
}
