table {
	border-collapse: collapse;
	width: 100%;
	//margin: 0 auto;
	font-size: 1.4rem;
	font-weight: 300;
	table-layout: auto;

	@media screen and (max-width: 650px) {
		font-size: 1.2rem;

		td {
			font-size: 1.1rem;
		}
	}

	@media screen and (max-width: 500px) {
		font-size: 1.1rem;

		td {
			font-size: 1rem;
		}
	}

	th,
	td {
		padding: 1rem;
		text-align: center;

		@media screen and (max-width: 650px) {
			padding: 0.7rem;
		}
	}

	th {
		background-color: darken($color-primary, 15%);
		color: $color-white;
	}

	@each $child in (4, 5, 6, 7, 8) {
		&.icon-#{$child} {
			td:nth-child(#{$child}),
			td:nth-child(#{$child + 1}),
			td:nth-child(#{$child + 2}) {
				width: 5%;
			}
			.btn-text {
				font-size: 2rem;
			}
		}
	}
}
