.blurr-bg {
	height: 100vh;
	width: 100%;
	position: fixed;
	justify-content: center;
	align-items: center;
	display: flex;
	top: 0;
	left: 0;
	background-color: rgba($color-dark, 0.8);
	padding: 15rem 0;
	z-index: 400;
	transition: all 0.3s;
	visibility: visible;

	@supports (-webkit-backdrop-filter: blur(10px)) or
		(backdrop-filter: blur(10px)) {
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		background-color: rgba($color-dark, 0.3);
	}
}
